<template>
  <div id="create-instance-form">
    <!-- Warning -->
    <VAlert v-if="errorMessage" class="mb-2" type="error" :title="$t('Error')" :message="errorMessage" />

    <!-- Steps -->
    <CreatingSteps v-if="startCreating" :form="form" @on-complete="removeUnloadEvent" />

    <!-- Form -->
    <VCard v-else class="w-100 mx-auto" :title="$t('Create an AIR Instance')">
      <hr class="mb-3 mt-2 bc-dark-5 w-100" />
      <form @submit.prevent="createInstance" autocomplete="off">
        <!-- First & Last Name -->
        <div class="form-row flex-row">
          <VTextBox
            name="First Name"
            class="flex-1 mr-2"
            label="First Name"
            v-model="form.firstname"
            v-validate="'alphanumeric'"
          />
          <VTextBox
            name="Last Name"
            class="flex-1 ml-2"
            label="Last Name"
            v-model="form.lastname"
            v-validate="'alphanumeric'"
          />
        </div>
        <!-- Email -->
        <div class="form-row">
          <VTextBox disabled name="Email" label="Email" v-model="form.email" />
        </div>
        <div class="form-row flex-row">
          <!-- Company -->
          <VTextBox
            class="flex-1 mr-2"
            name="Company"
            label="Company"
            v-model="form.company"
            v-validate="'alphanumeric|max:50|min:2'"
          />
          <!-- Domain -->
          <div class="d-flex flex-items-start flex-1 ml-2">
            <div class="d-flex flex-column w-100">
              <div class="d-flex flex-items-center w-100" style="margin-bottom: 6px">
                <label v-text="$t('Your Domain')" class="mb-0" />
                <button
                  type="button"
                  v-text="$t('Change')"
                  id="change-domain-btn"
                  @click="isDisabledDomainInput = !isDisabledDomainInput"
                />
              </div>
              <VTextBox
                nolabel
                name="Domain"
                v-model="form.domain"
                :disabled="isDisabledDomainInput"
                v-validate="'required'"
              />
            </div>
            <VTextBox disabled nolabel name="domain-input" class="domain-input" value=".binalyze.io" />
          </div>
        </div>
        <!-- Password -->
        <div class="form-row flex-row">
          <VPasswordStrengthMeter ref="passMeter" class="flex-1 mr-2" :value="form.password">
            <VTextBox
              type="password"
              ref="NewPassword"
              name="NewPassword"
              :label="$t('Password')"
              v-model="form.password"
              @focus="$refs.passMeter.show = true"
              @blur="$refs.passMeter.show = false"
              v-validate="'required|strong_password|max:100'"
              :placeholder="$t('Enter a password to log in to AIR')"
            />
          </VPasswordStrengthMeter>
          <!-- Confirm Password -->
          <VTextBox
            type="password"
            class="flex-1 ml-2"
            name="ConfirmPassword"
            v-model="form.confirmPassword"
            :label="$t('Confirm Password')"
            :data-vv-as="$t('Confirm Password')"
            v-validate="'required|strong_password|confirmed:NewPassword'"
          />
        </div>
        <!-- Create -->
        <hr class="my-3 bc-dark-5 w-100" />
        <div>
          <VButton
            :text="$t('Create Instance')"
            :loading="$wait.is($waiters.Customer.Create)"
            @click="createInstance"
            class="ml-auto"
            theme="primary"
          />
        </div>
      </form>
    </VCard>
  </div>
</template>

<script>
import { OneCustomerService } from '@/api/Services/OneCustomerService'
import { HTTPClient } from '@/api/HTTPClient'
import { validateCustomer } from '@/schemas/schemas'
import CreatingSteps from './CreatingSteps'
import { sleep } from '@/utils/Helpers'

export default {
  name: 'CreateAIRInstance',

  components: {
    CreatingSteps,
  },

  data() {
    return {
      form: {
        firstname: '',
        lastname: '',
        email: '',
        domain: '',
        password: '',
        confirmPassword: '',
        licenseKey: this.$route.query.licenseKey,
      },
      errorMessage: null,
      startCreating: false,
      isDisabledDomainInput: true,
    }
  },

  created() {
    this.customer = { ...this.$route.params.customer }
    Object.assign(this.form, this.customer)

    this.form.company = unescape(this.form.company)
    this.form.firstname = unescape(this.form.firstname)
    this.form.lastname = unescape(this.form.lastname)

    if (this.form.licenseKey) {
      HTTPClient.setParam('licenseKey', this.form.licenseKey)
      this.startCreating = true
    } else if (!this.customer || !validateCustomer(this.customer)) {
      return this.$router.push('/')
    }

    if (this.form.email) {
      this.generateDomainByName()
    }

    window.addEventListener('beforeunload', this.beforeUnload)
  },

  beforeDestroy() {
    this.removeUnloadEvent()
  },

  methods: {
    removeUnloadEvent() {
      window.removeEventListener('beforeunload', this.beforeUnload)
    },

    beforeUnload(e) {
      e.preventDefault()
      e.returnValue = false
    },

    async generateDomainByName() {
      const emailDomain = this.form.email.split('@')[1]?.split('.')?.[0]
      const { data } = await OneCustomerService.GenerateDomainByName(emailDomain || this.form.company)
      this.form.domain = data.result.domain
    },

    async createInstance() {
      if (!(await this.$validator.validateAll())) return

      this.errorMessage = null

      const onSuccess = async () => {
        const { data } = await OneCustomerService.CreateCustomer(this.form)
        this.form.licenseKey = data.result.licenseKey

        // A psychological wait to reduce 1.5 seconds from the next step.
        await sleep(1500)

        this.$router.replace({
          name: 'CreateAIRInstance',
          params: { fromCreation: true },
          query: { licenseKey: this.form.licenseKey },
        })
        HTTPClient.setParam('licenseKey', this.form.licenseKey)
        this.startCreating = true
      }

      const onError = async (error) => {
        this.errorMessage = error?.response?.data?.message || this.$t('Something went wrong')
      }

      this.$request(onSuccess, this.$waiters.Customer.Create, onError)
    },
  },
}
</script>

<style lang="scss">
#create-instance-form {
  width: 100%;
  max-width: 530px;
  margin: 0 auto;

  .domain-input {
    width: 142px;
    margin-top: 25.5px;

    input {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  #change-domain-btn {
    color: $color-blue;
    font-weight: 500;
    font-size: 11px;
    margin-left: 8px;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
