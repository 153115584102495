<template>
  <div class="d-flex flex-column flex-items-center">
    <VCheckLoader
      size="80px"
      class="mb-3"
      border="10px"
      :loading="isLoading"
      :icon="isValidCustomer ? 'check' : 'times'"
      :color="isLoading || isValidCustomer ? '#438af1' : '#e74c3c'"
    />
    <span v-text="loaderText" class="fs-big fw-medium" />
  </div>
</template>

<script>
import { OneCustomerService } from '@/api/Services/OneCustomerService'
import { validateCustomer } from '@/schemas/schemas'

export default {
  name: 'ConfirmCustomer',

  data() {
    return {
      isValidCustomer: false,
    }
  },

  created() {
    this.customer = this.$route.query

    if (validateCustomer(this.customer)) {
      this.confirmCustomer()
    } else {
      this.redirectToBinalyze()
    }
  },

  computed: {
    isLoading() {
      return this.$wait.is(this.$waiters.Customer.Confirm)
    },

    loaderText() {
      if (this.isLoading && !this.isValidCustomer) {
        return this.$t('Confirming...')
      } else if (!this.isValidCustomer) {
        return this.$t('Could not be confirmed.')
      }
      return this.$t('Confirmed.')
    },
  },

  methods: {
    redirectToBinalyze() {
      setTimeout(() => (window.location.href = 'https://binalyze.com'), 4e3)
    },

    async confirmCustomer() {
      const onSuccess = async () => {
        await OneCustomerService.ConfirmCustomer({
          email: this.customer.email,
          token: this.customer.token,
        })

        this.isValidCustomer = true

        setTimeout(() => {
          this.$router.push({
            name: 'CreateAIRInstance',
            params: {
              customer: this.customer,
            },
          })
        }, 500)
      }

      const onError = (error) => {
        this.redirectToBinalyze()
        console.log(error?.response)
      }

      this.$request(onSuccess, this.$waiters.Customer.Confirm, onError)
    },
  },
}
</script>
