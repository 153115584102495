import * as Ajv from 'ajv'
import addFormats from 'ajv-formats'

const ajv = new Ajv({ allErrors: true })
addFormats(ajv)

export const customerSchema = {
  properties: {
    firstname: { type: 'string' },
    lastname: { type: 'string' },
    email: { type: 'string', format: 'email' },
    company: { type: 'string' },
    token: { type: 'string' },
  },
  required: ['firstname', 'lastname', 'email', 'company', 'token'],
  additionalProperties: false,
}

export const validateCustomer = ajv.compile(customerSchema)
